// grab the images
const scrollElements = [...(document.querySelectorAll('.image-alt-container img'))];

// define helper function - is the element in the viewport or not?
const isInViewport = function (elem) {
    const bounding = elem.getBoundingClientRect();
    return (
        bounding.top >= 0 &&
        bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) 
    );
};


// define function 
const addScrollEffect = function(els){
    // if elements are on page, otherwise ignore
    if(els){
        // listen for scroll 
        // then check to see when els enter/leave viewport, and apply animations via css class
        window.addEventListener('scroll', () => {
            els.forEach(el => {
                isInViewport(el) ? el.classList.remove('offscreen') : el.classList.add('offscreen');
            })
        })
    }
}

// initialze it
// addScrollEffect(scrollElements);