function setCookie(cname, cvalue, exdays) {
  var d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  var expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
          c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
      }
  }
  return "";
}

function ieAccept() {
    jQuery('#top_menu').removeClass('ie-banner-margin-desktop');
    jQuery('.global-header-container').removeClass('ie-banner-margin-mobile');
    var ieElement =  document.getElementById("ie-banner");
    ieElement.parentNode.removeChild(ieElement);
    document.cookie = "IE_Acknowledged=accepted;Path=/";
  }

function checkCookie() {
  //accept site cookies banner
  var cookieIsSet = getCookie('accept_cookies');
  var ieCookieIsSet = getCookie('IE_Acknowledged');
  if (cookieIsSet != "") {
      jQuery('.page-wrapper').css('padding-bottom', '0');
  } else {
      jQuery('#cookieSpace').slideDown();
      jQuery('#cookie_accept').on('change', function(event) {
          setCookie('accept_cookies', 'cookies_accpeted', '30');
          jQuery('#cookieSpace').slideUp();
      });
  }
  if (ieCookieIsSet != "") {
    var ua = window.navigator.userAgent;
    var msie = ua.indexOf("MSIE ");
    jQuery('#ie-banner').hide();
    jQuery('.global-header-container').removeClass('ie-banner-margin-mobile');
    jQuery('#top_menu').removeClass('ie-banner-margin-desktop');
  } else if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
      jQuery('#ie-banner').css('display', 'block');
      jQuery('.global-header-container').addClass('ie-banner-margin-mobile');
      jQuery('#top_menu').addClass('ie-banner-margin-desktop');
  } else {
  }
}
jQuery(document).ready(function() {
  checkCookie();
});