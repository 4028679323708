const backToTopButton = document.querySelector(".back-to-top");

// const backToTop = () => {
//   document.body.scrollTop = 0; // For Safari
//   document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
// };

// if (backToTopButton) {
//   backToTopButton.addEventListener("click", (e) => {
//     backToTop();
//   });
// }

const backToTop = () => {
	const c = document.documentElement.scrollTop || document.body.scrollTop;
  	if (c > 0) {
	    window.requestAnimationFrame(backToTop);
	    window.scrollTo(0, c - c / 8);
	}
};

if (backToTopButton) {
  backToTopButton.addEventListener("click", (e) => {
    backToTop();
  });
}

// todo: add to scroll.js listener
// todo: set min-height threshold to display in footer?
// todo: set min-height threshold to display as fixed on page?
// todo: animate?
