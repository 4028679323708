// set state
let searchClosed = true;
// some vars that will prob be used elsewhere,
// so they are stored outside function scope
const searchTrigger = document.querySelector(".search-toggle");
const searchBox = document.querySelector(".search-form__input");

// this will expand and contract the search
// could be made more generic with some arguments
function toggleSearch() {
  // is contracted(hidden)?
  if (searchClosed) {
    // give the input focus
    searchBox.focus();
    // add opacity to main nav links per design
    mainNav.classList.add("subdued");
    // set the state as not closed, or open
    return (searchClosed = false);
  } else {
    // remove opacity from main nav links
    mainNav.classList.remove("subdued");
    // set state as closed
    return (searchClosed = true);
  }
}

// apply the above
searchTrigger.addEventListener(
  "click",
  (e) => {
    toggleSearch();
  },
  true
);

searchBox.addEventListener("blur", (e) => {
  if (mainNav.classList.contains("subdued")) {
    mainNav.classList.remove("subdued");
  }
  // because js runs in sync
  // we need to delay setting state
  // for when the toggleSearch is being initialized
  // on searchTrigger click event
  setTimeout(() => {
    return (searchClosed = true);
  }, 300);
});
