jQuery(function($) {

    $('.sliderquote-modalwrrapper .modal').on('shown.bs.modal', function () {
      let vimeo = $(this).find('.modalwrrapper--vimeo');
      if(vimeo.length > 0) {
        let vimeoURL = $(vimeo).data('url')+'&autoplay=1';
        $(vimeo).append('<iframe src="'+vimeoURL+'" width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>');
      }
    });
    $('.sliderquote-modalwrrapper .modal').on('hidden.bs.modal', function () {
      let vimeo = $(this).find('.modalwrrapper--vimeo');
      if(vimeo.length > 0) {
        $(vimeo).find('iframe').remove();
      }
    });


    $(".sliderquote--slider").each(function(index, el) {
      let autoplay = parseInt($(el).data('autoplay')) == 1 ? true : false;
      let autoplaySpeed = parseInt($(el).data('interval')) > 1 ? parseInt($(el).data('interval')) : 3000;
      if (el) {
        let splide = new Splide(el, {
          type: 'loop',
          perPage: parseInt($(el).data('perpage')),
          perMove: parseInt($(el).data('perpage')),
          gap: "50px",
          pagination: false,
          arrows: true,
          arrowPath: 'M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z',
          padding: { left: 0, right: '12rem' },
          autoplay: autoplay,
          interval: autoplaySpeed,
          pauseOnHover: true,
          resetProgress: true,
          breakpoints: {
            1024: {
              perPage: 1,
              gap: "30px",
              padding: { left: 0, right: '8rem' },
            },
          }
        });
        splide.mount();
      }
    });
  
});