jQuery(function($) {

    $(window).on('load', function() {
        $('.sbs-animated--cols').each(function(i, element) {
            // Make image fixed height for animation
           $(element).find('.sbs-animated--img').first().height($(element).height());
            // Make text fixed width for animation
            $(element).find('.sbs-animated--text-inner').first().width($(element).find('.sbs-animated--text-inner').first().width());
            $(element).find('.sbs-animated--text-inner').first().height($(element).find('.sbs-animated--text-inner').first().height());

           $(element).addClass('sbs-animated--cols-texthidden');
           $(element).addClass('sbs-animated--cols-ready');

           console.log($(element).height());

           $(element).on({
                mouseenter: function () {
                    $(this).removeClass('sbs-animated--cols-texthidden');
                },
                mouseleave: function () {
                    $(this).addClass('sbs-animated--cols-texthidden');
                }
            });
           
        });
    });

    // $('.sbs-animated--cols').on({
    //     mouseenter: function () {
    //         $(this).removeClass('sbs-animated--cols-texthidden');
    //     },
    //     mouseleave: function () {
    //         $(this).addClass('sbs-animated--cols-texthidden');
    //     }
    // });

    /*$(window).on('resize scroll', function() {

        $('.sbs-animated--cols').each(function(i, element) {
            if ($(element).isInViewport()) {
                $(element).removeClass('sbs-animated--cols-texthidden');
            } else {
                $(element).addClass('sbs-animated--cols-texthidden');
            }
        });
    });


    $.fn.isInViewport = function() {
        let elementTop = $(this).offset().top;
        let elementBottom = elementTop + $(this).outerHeight();
    
        let viewportTop = $(window).scrollTop();
        let viewportBottom = viewportTop + $(window).height();
    
        return elementBottom > viewportTop && elementTop < viewportBottom;
    };*/
});