jQuery(function($) {

  $('.videomodal-modalwrrapper .modal').on('shown.bs.modal', function () {
    let vimeo = $(this).find('.videomodal--vimeo');
    if(vimeo.length > 0) {
      let vimeoURL = $(vimeo).data('url')+'&autoplay=1';
      $(vimeo).append('<iframe src="'+vimeoURL+'" width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>');
    }
    else {
      let mp4Video = $(this).find('.videomodal--mp4 > video').get(0);
      mp4Video.play();
    }
  });
  $('.videomodal-modalwrrapper .modal').on('hidden.bs.modal', function () {
    let vimeo = $(this).find('.videomodal--vimeo');
    if(vimeo.length > 0) {
      $(vimeo).find('iframe').remove();
    }
    else {
      let mp4Video = $(this).find('.videomodal--mp4 > video').get(0);
      mp4Video.play();
    }
  });

});