// let jumpMenuClosed = true;
// const jumpTrigger = document.querySelector(".jump-trigger");
// const jumpList = document.querySelector(".jump-links-list");
// const jumpMenuItems = Array.from(document.querySelectorAll(".jump-link"));

// function jumpMenu() {
//   if (jumpTrigger) {
//     jumpTrigger.addEventListener("click", (e) => {
//       e.stopPropagation();
//       if (jumpMenuClosed) {
//         jumpList.classList.add("opened");
//         jumpTrigger.classList.add("active-list");
//         return (jumpMenuClosed = false);
//       } else {
//         jumpList.classList.remove("opened");
//         jumpTrigger.classList.remove("active-list");
//         return (jumpMenuClosed = true);
//       }
//     });
//   }
// }

// jumpMenuItems.forEach((el) => {
//   if (jumpMenuItems) {
//     el.addEventListener("click", (e) => {
//       jumpList.classList.remove("opened");
//       jumpTrigger.classList.remove("active-list");
//       return (jumpMenuClosed = true);
//     });
//   }
// });


jQuery(function ($) {
  /* Global Vars */
  var $window = $(window),
    $document = $(document),
    scrolled = false,
    jumpTrigger = $(".jump-trigger"),
    jumpList = $(".jump-links-list"),
    jumpMenuItems = jQuery.makeArray($(".jump-link")),
    jumpMenuClosed = true;


$document.ready(function () {
  function jumpMenu() {
    if (jumpTrigger) {
      jumpTrigger.click(function (e) {
          e.preventDefault();
        if (jumpMenuClosed) {
          jumpList.addClass("opened");
          jumpTrigger.addClass("active-list");
          jumpMenuClosed = false;
        } else {
          jumpList.removeClass("opened");
          jumpTrigger.removeClass("active-list");
          jumpMenuClosed = true;
        }
      });
    }
  }

    jumpMenuItems.forEach((el) => {
      if (jumpMenuItems) {
        el.addEventListener("click", (e) => {
          jumpList.classList.remove("opened");
          jumpTrigger.classList.remove("active-list");
          return (jumpMenuClosed = true);
        });
      }
    });

jumpMenu();

 }); //end ready
}); //end main $ function

// Smooth scroll with negative margin for hashtag links
jQuery(function ($) {
  /* Global Vars */
  var $window = $(window),
      $document = $(document),
      scrolled = false;
  $document.ready(function () {
    $('.jump-link').on('click',function(e) {
      e.preventDefault();
      var offset = 100;
      var target = this.hash;
      if ($(this).data('offset') != undefined) offset = $(this).data('offset'); //Each target can have a specific value by add a data-offset attribute with a numeric value
      $('html, body').stop().animate({
        'scrollTop': $(target).offset().top - offset
      }, 500, 'swing', function() {
        // window.location.hash = target;
      });
    });
  }); //end ready
}); //end main $ function
