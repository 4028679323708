// const langMenu = document.querySelector("li.custom-dropdown");
// const langMenuTrigger = document.querySelector("li.custom-dropdown > a");
// let langMenuClosed = true;

// if (langMenu) {
//   langMenuTrigger.addEventListener("click", (e) => {
//     e.preventDefault;
//     if (langMenuClosed) {
//       langMenu.classList.add("opened");

//       return (langMenuClosed = false);
//     } else {
//       langMenu.classList.remove("opened");
//       return (langMenuClosed = true);
//     }
//   });

//   langMenu.addEventListener("mouseover", (e) => {
//     langMenu.classList.add("opened");
//     return (langMenuClosed = false);
//   });

//   langMenu.addEventListener("mouseout", (e) => {
//     langMenu.classList.remove("opened");
//     return (langMenuClosed = true);
//   });
// }

jQuery(function ($) {
  /* Global Vars */
  var $window = $(window),
    $document = $(document),
    scrolled = false,
    langMenu = $("li.custom-dropdown"),
    langMenuTrigger = $("li.custom-dropdown > a"),
    langMenuClosed = true;

  $document.ready(function () {
    if (langMenu) {
      langMenuTrigger.click(function () {
        e.preventDefault();
        if (langMenuClosed) {
          langMenu.addClass("opened");
          langMenuClosed = false;
        } else {
          langMenu.removeClass("opened");
          langMenuClosed = true;
        }
      });

      langMenu.mouseover(function (e) {
        langMenu.addClass("opened");
        langMenuClosed = false;
      });

      langMenu.mouseout(function (e) {
        langMenu.removeClass("opened");
        langMenuClosed = true;
      });
    }
  }); //end ready
}); //end main $ function