jQuery(function ($) {
  /* Global Vars */
  var $window = $(window),
    $document = $(document),
    scrolled = false;

  $document.ready(function () {
    // Tabs for Tabbed Component
    $(".tabs li a").click(function () {
      var t = $(this).attr("id");

      if ($(this).hasClass("inactive")) {
        $(this).closest(".tabs-container").find(".tabs li a").addClass("inactive");
        $(this).closest(".tabs-container").find(".tabs li").addClass("inactive");
        $(this).removeClass("inactive");
        $(this).closest("li").removeClass("inactive");
        $(this).closest(".tabbed-wrapper").find(".tab-pane").removeClass("active");
        $(this).closest(".tabbed-wrapper").find(".tab-pane").removeClass("inactive-map");
        $(this).closest(".tabbed-wrapper").find("#" + t + "C").addClass("active");
        $(this).closest(".tabbed-wrapper").find(".tab-pane").hide();
        $(this).closest(".tabbed-wrapper").find("#" + t + "C").fadeIn("slow");
      }
    });

    // Expanding descriptions for Tabbed Component.
    $(".tab-content").on("click", ".tabbed-dd", getText);

    function getText(e) {
      e.preventDefault();

      $(".tabbed-description").remove();

      var collection = $(".tabbed-post-wrap");
      var num = 0;
      var num = $(this).closest(".tab-pane.active .tabbed-post-wrap").index();
      var numfourth = (num + 1) % 4;
      var numthird = (num + 1) % 3;
      var numsecound = (num + 1) % 2;
      var numfirst = num % 4;
      var numfirstforthree = num % 3;
      var numfirstfortwo = num % 2;
      var numtotal = $(this).closest(".tab-row").find(".tabbed-post-wrap").length;
      var id = $(this).attr("data-id");
      var imgsrc = $(this).attr("data-img-src");
      var imgalt = $(this).attr("data-img-alt");
      var imgtitle = $(this).attr("data-img-title");
      var title = $(this).attr("data-title");
      var jobtitle = $(this).attr("data-job-title");
      var content = $(this).attr("data-content");
      var newDiv =
        '<div class="tabbed-description slide" id="' +
        id +
        '"><div id="slideClose" class="close">X</div><div class="slide-content-wrap"><div class="text_content">' +
        content +
        '</div><div class="clearfix"></div></div></div>';
      var afterNum;

      // isDesktop = true;

      if ($(this).hasClass("clicked")) {
        $(this).removeClass("clicked");
        $(this).closest(".tabbed-post-wrap").removeClass("tabbed-expanded-bg");
        $(".tabbed-description").remove();
        $(".tabbed-post-wrap").css("margin-bottom", "50px");
      } else {

        // 4 column Section
        if ($(window).width() >= 1200) {
          var numremain = numtotal % 4;

          //Less then 4 items
          if (numtotal < 4) {

            if (numremain == 3) {
              if (numthird == 0) {
                //is third
                afterNum = num;
              } else {
                if (num === $(this).closest(".tab-content").find(".tab-pane.active .tabbed-post-wrap").length - 1) {
                  afterNum = parseFloat(num); //is last
                } else if (numfirstforthree == 0) {
                  afterNum = parseFloat(num) + 2; //is first
                } else {
                  afterNum = parseFloat(num) + 1; //is middle
                }
              }

            } else if (numremain == 2) {
              if (numsecound == 0) {
                //is second
                afterNum = num;
              } else {
                if (num === $(this).closest(".tab-content").find(".tab-pane.active .tabbed-post-wrap").length - 1) {
                  afterNum = parseFloat(num); //is last
                } else {
                  afterNum = parseFloat(num) + 1; //is first
                }
              }

            } else if (numremain == 1) {
              afterNum = num;
            } else {
              //do nothing
            }
          // If there are remainders
          } else if (numremain > 0) {
            if (numremain == 3) {
              if (num < (numtotal - 3)) {
                if (numfourth == 0) {
                  //is forth
                  afterNum = num;
                } else {
                  if (num === $(this).closest(".tab-content").find(".tab-pane.active .tabbed-post-wrap").length - 1) {
                    afterNum = parseFloat(num); //is last
                  } else if (numfirst == 0) {
                    afterNum = parseFloat(num) + 3; //is first
                  } else if (numfirst == 1) {
                    afterNum = parseFloat(num) + 2; //is second
                  } else {
                    afterNum = parseFloat(num) + 1; //is third
                  }
                }
              } else {
                if (num === $(this).closest(".tab-content").find(".tab-pane.active .tabbed-post-wrap").length - 1) {
                  afterNum = parseFloat(num); //is last
                } else if (numfirst == 1) {
                  afterNum = parseFloat(num) + 1; //is first
                } else {
                  afterNum = parseFloat(num) + 2; //is middle
                }
              }

            } else if (numremain == 2) {
              if (num < (numtotal - 2)) {
                if (numfourth == 0) {
                  //is forth
                  afterNum = num;
                } else {
                  if (num === $(this).closest(".tab-content").find(".tab-pane.active .tabbed-post-wrap").length - 1) {
                    afterNum = parseFloat(num); //is last
                  } else if (numfirst == 0) {
                    afterNum = parseFloat(num) + 3; //is first
                  } else if (numfirst == 1) {
                    afterNum = parseFloat(num) + 2; //is second
                  } else {
                    afterNum = parseFloat(num) + 1; //is third
                  }
                }
              } else {
                if (num === $(this).closest(".tab-content").find(".tab-pane.active .tabbed-post-wrap").length - 1) {
                  afterNum = parseFloat(num); //is last
                } else {
                  afterNum = parseFloat(num) + 1; //is first
                }
              }
 
            } else if (numremain == 1) {
              if (num < numtotal - 1) {
                if (numfourth == 0) {
                  //is forth
                  afterNum = num;
                } else {
                  if (num === $(this).closest(".tab-content").find(".tab-pane.active .tabbed-post-wrap").length - 1) {
                    afterNum = parseFloat(num); //is last
                  } else if (numfirst == 0) {
                    afterNum = parseFloat(num) + 3; //is first
                  } else if (numfirst == 1) {
                    afterNum = parseFloat(num) + 2; //is second
                  } else {
                    afterNum = parseFloat(num) + 1; //is third
                  }
                }
              } else {
                afterNum = num;
              }
            } else {
              //do nothing
            }
          //If there are no remainders
          } else if (numfourth == 0) {
            //is forth
            afterNum = num;
          } else {
            if (num === $(this).closest(".tab-content").find(".tab-pane.active .tabbed-post-wrap").length - 1) {
              afterNum = parseFloat(num); //is last
            } else if (numfirst == 0) {
              afterNum = parseFloat(num) + 3; //is first
            } else if (numfirst == 1) {
              afterNum = parseFloat(num) + 2; //is second
            } else {
              afterNum = parseFloat(num) + 1; //is third
            }
          }
          $(this).closest(".tab-content").find(".tab-pane.active .tabbed-post-wrap")[afterNum].insertAdjacentHTML(
            "afterend",
            newDiv
          );
          $(".tabbed-dd").removeClass("clicked");
          $(".slide").removeClass("bio_open");
          $(this).addClass("clicked");
          $(".slide").addClass("bio_open");
          $(".tabbed-post-wrap").removeClass("tabbed-expanded-bg");
          $(this).closest(".tabbed-post-wrap").addClass("tabbed-expanded-bg");
          $(".tabbed-post-wrap").css("margin-bottom", "0");

          // 3 Column Section
        } else if ($(window).width() >= 900) {
          var numremain = numtotal % 3;

          //Less then 3 items
          if (numtotal < 3) {

            if (numremain == 2) {
              if (numsecound == 0) {
                //is second
                afterNum = num;
              } else {
                if (num === $(this).closest(".tab-content").find(".tab-pane.active .tabbed-post-wrap").length - 1) {
                  afterNum = parseFloat(num); //is last
                } else {
                  afterNum = parseFloat(num) + 1; //is first
                }
              }

            } else if (numremain == 1) {
              afterNum = num;
            } else {
              //do nothing
            }
          // If there are remainders
          } else if (numremain > 0) {
            if (numremain == 2) {
              if (num < (numtotal - 2)) {
                if (numthird == 0) {
                  //is forth
                  afterNum = num;
                } else {
                  if (num === $(this).closest(".tab-content").find(".tab-pane.active .tabbed-post-wrap").length - 1) {
                    afterNum = parseFloat(num); //is last
                  } else if (numfirstforthree == 0) {
                    afterNum = parseFloat(num) + 2; //is first
                  } else {
                    afterNum = parseFloat(num) + 1; //is middle
                  }
                }
              } else {
                if (num === $(this).closest(".tab-content").find(".tab-pane.active .tabbed-post-wrap").length - 1) {
                  afterNum = parseFloat(num); //is last
                } else {
                  afterNum = parseFloat(num) + 1; //is first
                }
              }
 
            } else if (numremain == 1) {
              if (num < numtotal - 1) {
                if (numthird == 0) {
                  //is third
                  afterNum = num;
                } else {
                  if (num === $(this).closest(".tab-content").find(".tab-pane.active .tabbed-post-wrap").length - 1) {
                    afterNum = parseFloat(num); //is last
                  } else if (numfirstforthree == 0) {
                    afterNum = parseFloat(num) + 2; //is first
                  } else {
                    afterNum = parseFloat(num) + 1; //is middle
                  }
                }
              } else {
                afterNum = num;
              }
            } else {
              //do nothing
            }
          // If there are no remainders
          } else if (numthird == 0) {
            //is third
            afterNum = num;
          } else {
            if (num === $(this).closest(".tab-content").find(".tab-pane.active .tabbed-post-wrap").length - 1) {
              afterNum = parseFloat(num); //is last
            } else if (numfirstforthree == 0) {
              afterNum = parseFloat(num) + 2; //is first
            } else {
              afterNum = parseFloat(num) + 1; //is middle
            }
          }
          $(this).closest(".tab-content").find(".tab-pane.active .tabbed-post-wrap")[afterNum].insertAdjacentHTML(
            "afterend",
            newDiv
          );
          $(".tabbed-dd").removeClass("clicked");
          $(".slide").removeClass("bio_open");
          $(this).addClass("clicked");
          $(".slide").addClass("bio_open");
          $(".tabbed-post-wrap").removeClass("tabbed-expanded-bg");
          $(this).closest(".tabbed-post-wrap").addClass("tabbed-expanded-bg");
          $(".tabbed-post-wrap").css("margin-bottom", "0");

          // 2 Column Section
        } else if ($(window).width() >= 600) {
          if (numsecound == 0) {
            //is second
            afterNum = num;
          } else {
            if (
              num === $(this).closest(".tab-content").find(".tab-pane.active .tabbed-post-wrap").length - 1) {
              afterNum = parseFloat(num); //is last
            } else {
              afterNum = parseFloat(num) + 1; //is first
            }
          }

          $(this).closest(".tab-content").find(".tab-pane.active .tabbed-post-wrap")[afterNum].insertAdjacentHTML(
            "afterend",
            newDiv
          );
          $(".tabbed-dd").removeClass("clicked");
          $(".slide").removeClass("bio_open");
          $(this).addClass("clicked");
          $(".slide").addClass("bio_open");
          $(".tabbed-post-wrap").removeClass("tabbed-expanded-bg");
          $(this).closest(".tabbed-post-wrap").addClass("tabbed-expanded-bg");
          $(".tabbed-post-wrap").css("margin-bottom", "0");
        } else {
          afterNum = parseFloat(num);
          $(this).closest(".tab-content").find(".tab-pane.active .tabbed-post-wrap")[afterNum].insertAdjacentHTML(
            "afterend",
            newDiv
          );
          $(".tabbed-dd").removeClass("clicked");
          $(".slide").removeClass("bio_open");
          $(this).addClass("clicked");
          $(".slide").addClass("bio_open");
          $(".tabbed-post-wrap").removeClass("tabbed-expanded-bg");
          $(this).closest(".tabbed-post-wrap").addClass("tabbed-expanded-bg");
          $(".tabbed-post-wrap").css("margin-bottom", "0");
        }
      }
    }

    $(document).on("click", "#slideClose", function () {
      $(".tabbed-description").remove();
      $(".tabbed-post-wrap").css("margin-bottom", "50px");
      $(".tabbed-dd").removeClass("clicked");
      $(".slide").removeClass("bio_open");
      $(".tabbed-post-wrap").removeClass("tabbed-expanded-bg");
    });

    // Video play on click
    // $(document).on("click", ".poster", function () {
    //   $(this).fadeOut();
    //   $(this).next().fadeOut();
    //   $(this).parent().find('iframe').attr('src', $(this).parent().find('iframe').attr('src') + '?autoplay=1');
    //   $(this).parent().find('video').get(0).play();
    // });
    // $(document).on("click", ".icon-video", function () {
    //   $(this).fadeOut();
    //   $(this).prev().fadeOut();
    //   $(this).parent().find('iframe').attr('src', $(this).parent().find('iframe').attr('src') + '?autoplay=1');
    //   $(this).parent().find('video').get(0).play();
    // });

    // Alternating Negative Margin
    $("#alt-slider").parent().next().addClass("negMargin");
  }); //end ready
}); //end main $ function
