const menuItems = document.querySelectorAll("li.dropdown__item");

const toggleVisibilityOnHover = (container, anchor, button, header) => {
  container.addEventListener("mouseover", (event) => {
    if (window.getComputedStyle(button).display == "none") {
      // change bg color
      header.classList.add("activated");
      // show the content
      container.classList.add("open");
      // set aria for a11y
      anchor.setAttribute("aria-expanded", "true");
      button.setAttribute("aria-expanded", "true");
    }
  });
  container.addEventListener("mouseout", (event) => {
    if (window.getComputedStyle(button).display === "none") {
      // revert bg to transparent
      header.classList.remove("activated");
      // hide content
      container.classList.remove("open");
      // update aria
      anchor.setAttribute("aria-expanded", "false");
      button.setAttribute("aria-expanded", "false");
    }
  });
};

const toggleVisibilityOnClick = (trigger, anchor, header) => {
  trigger.addEventListener("click", (event) => {
    if (trigger.parentNode.classList.contains("open")) {
      trigger.parentNode.classList.remove("open");
      header.classList.remove("activated");
      anchor.setAttribute("aria-expanded", "false");
      trigger.setAttribute("aria-expanded", "false");
    } else {
      trigger.parentNode.classList.add("open");
      header.classList.add("activated");
      anchor.setAttribute("aria-expanded", "false");
      trigger.setAttribute("aria-expanded", "false");
    }
    event.preventDefault();
  });
};

Array.prototype.forEach.call(menuItems, function (el, i) {
  const anchor = el.querySelector("a");
  const button = el.querySelector("button");
  const buttonText = button.querySelector("span");
  buttonText.textContent = anchor.text;
  const globalHeader = document.querySelector("header.global-header");

  toggleVisibilityOnHover(el, anchor, button, globalHeader);
  toggleVisibilityOnClick(button, anchor, globalHeader);
});


// TODO: Class or Module?