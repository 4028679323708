let scrollpos = window.scrollY;
let scrollClass = "scrolled";
let desktopThreshold = 1200;

const globalHeader = document.querySelector("#global-header");
const add_class_on_scroll = () => globalHeader.classList.add(scrollClass);
const remove_class_on_scroll = () => globalHeader.classList.remove(scrollClass);

// i made up this number
// todo: create rules for threshold value?
const scrollThreshold = 60;

if (window.screen.width >= desktopThreshold) {
  window.addEventListener("scroll", function () {
    scrollpos = window.scrollY;

    if (scrollpos >= scrollThreshold) {
      add_class_on_scroll();
    } else {
      remove_class_on_scroll();
    }
  });
}
