const heroSection = document.querySelector(".acf-hero");

if (heroSection) {
  const heroSlider = heroSection.querySelector("#hero-slider");
  const heroStatic = heroSection.querySelector("#hero-static");
  const jumpLinksTest = heroSection.querySelector(".jump-links-bg-container");
  const breadCrumbsTest = heroSection.querySelector(
    ".breadcrumbs-bg-container"
  );

  if (heroSlider) {
    heroSection.classList.add("hero-slider");
  }

  if (heroStatic) {
    heroSection.classList.add("hero-static");
  }

  if (jumpLinksTest) {
    heroSection.classList.add("has-jump");
  } else if (breadCrumbsTest) {
    heroSection.classList.add("has-breadcrumbs");
  }

  // remove for prod
  //console.log(heroSection.classList);
}
